<template>
  <div class="form field" >
    <label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;" class="label">404 Page settings</label>
    <v-tabs class="langs__tabs"  v-model="activeLang">
      <v-tab
          v-for="lang in landing.advanced.selected_langs"
          :key="lang+'lite'"
          @change="changeLang(lang)"
      >
        <img :src="landing.advanced.langs.find(x => x.value === lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
        {{ lang }}
      </v-tab>
      <v-tab-item v-for="lang in landing.advanced.selected_langs" :key="lang+'pro'">
        <div v-if="page_404&&page_404[lang]">
          <label class="label" style="display: block;margin-bottom: 15px;">404 Page</label>
          <div class="control">
            <Editor
                api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                :init="options"
                v-model="page_404[lang].content"
                @onChange="changePage"
            >
            </Editor>
          </div>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "Page404",
  data(){
    return{
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: 'jpg,svg,webp,gif,png',
          images_upload_handler: async  (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
            formData.append("lending_settings_id", this.$store.state.landing.settings_id);
            formData.append("image", _file);
            formData.append("file", _file);
            const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
            success(data);
          },
          plugins:
              "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

        setup:function(editor){
          editor.ui.registry.addButton('custom_button', {
            text: 'Add Button',
            onAction: function() {
              // Open a Dialog
              editor.windowManager.open({
                title: 'Add custom button',
                body: {
                  type: 'panel',
                  items: [{
                    type: 'input',
                    name: 'button_label',
                    label: 'Button label',
                    flex: true
                  },{
                    type: 'input',
                    name: 'button_href',
                    label: 'Button href',
                    flex: true
                  },{
                    type: 'selectbox',
                    name: 'button_target',
                    label: 'Target',
                    items: [
                      {text: 'None', value: ''},
                      {text: 'New window', value: '_blank'},
                      {text: 'Self', value: '_self'},
                      {text: 'Parent', value: '_parent'}
                    ],
                    flex: true
                  },{
                    type: 'selectbox',
                    name: 'button_style',
                    label: 'Style',
                    items: [
                      {text: 'Success', value: 'success'},
                      {text: 'Info', value: 'info'},
                      {text: 'Warning', value: 'warning'},
                      {text: 'Error', value: 'error'}
                    ],
                    flex: true
                  }]
                },
                onSubmit: function (api) {

                  var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                  // insert markup
                  editor.insertContent(html);

                  // close the dialog
                  api.close();
                },
                buttons: [
                  {
                    text: 'Close',
                    type: 'cancel',
                    onclick: 'close'
                  },
                  {
                    text: 'Insert',
                    type: 'submit',
                    primary: true,
                    enabled: false
                  }
                ]
              });
            }
          });
        },
        content_css: ["/custom_tinymce.css"],
      },
    }
  },
  computed:{
    ...mapState(['landing']),
    ...mapState('advanced',['page_404']),
    activeLang:{
      set:function(val){
      },
      get:function(){
        return this.landing.advanced.selected_langs.indexOf(this.landing.current_lang)
      }
    },
  },
  methods:{
    ...mapMutations('landing',['setLandingPage404']),
    ...mapMutations('advanced',['setPage404']),
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
    changePage(){
      this.setLandingPage404(JSON.parse(JSON.stringify(this.page_404)));
    }
  },
  mounted(){
    this.setPage404(JSON.parse(JSON.stringify(this.landing.advanced.global.page_404)));
  }
}
</script>

<style scoped>

</style>
